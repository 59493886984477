/* eslint-disable */

import 'styles/index.pcss';
import 'styles/application.scss';

import { WindIcon } from 'components/wind-icon';
import initSwellIcons from 'components/swell-icon';
import 'components/weather-icon';
import 'components/icon';
import 'components/radio-button';
import 'components/toggle-button';
import tideTable from 'components/tide-table';
import forecastTable from 'components/forecast-table';
import switchUnits from 'components/switch-units';
import { TempValue } from 'components/temp-value';
import { RainAmount } from 'components/rain-amount';
import { loadTides } from 'utils/tide/tides';
import Units from 'utils/units';
import airbrake from 'utils/airbrake';
import DomLoaded from 'utils/dom-loaded';
import PageLoaded from 'utils/page-loaded';

import polyfills from 'utils/polyfills';
import initUnits from 'utils/switch-units';
import sticky from 'components/sticky';
import 'components/selections';
import 'components/header-form';
import 'components/mobile-navigation';
import 'components/mobile-button-sticky';
import 'components/read-more';
import 'components/tab-text-links';
import tideHeader from 'components/tide-header';
import miniTide from 'components/mini-tide';
import 'components/tide-day';
import 'components/homepage-mast';
import 'components/homepage-locations';
import 'components/tide-key';
// import 'components/banner-background';
import 'components/footer';
import 'components/countries-mast';
import 'components/country-mast';
import 'components/watermark';
import 'components/print-link';
import 'components/header-logo';
import scrollFade from 'components/scroll-fade';
import tidesDot from 'components/tides-dot';
import tidesContentImage from 'components/tides-content-image';
import twoUnitsValue from 'components/two-units-value';
import 'components/page-header';
import 'components/mobile-menu';
import 'components/top-navigation';
import 'components/locations-selections';
import { Nearby } from 'components/nearby';
import 'components/latlong';
import tideTableHeader from 'components/tide-table-header';
import scrollButton from 'components/scroll-button/scroll-button';
import 'components/top-tabs';
import 'fc-ads/fc-ads.scss';
import 'utils/web-vitals-analytics';
import { refreshPosCookie, updatePosCookie} from 'utils/coords';
import { Map } from 'components/leaflet';

DomLoaded(() => {
  try {
    polyfills();
    initUnits(window.FCGON.ip_units);
    scrollFade();
    switchUnits();
    loadTides();

    try {
      tideHeader();
    } catch(e) {
      airbrake.notify(e);
    }

    try {
      miniTide();
    } catch(e) {
      airbrake.notify(e);
    }

    tidesContentImage();
    twoUnitsValue();
    WindIcon.init(document.body);
    initSwellIcons(document, Units);
    tideTableHeader();
    tideTable(window.FCGON || {}, function () {
      // window.FCAds = window.FCAds || { BNSlots: [], BNQueue: [] };
      // FCAds.BNQueue.push('cont_bn_in_tide_chart');
    });
    forecastTable();
    scrollButton();
  } catch(e) {
    airbrake.notify(e);
  }
});

PageLoaded(() => {
  sticky();
  tidesDot();
  TempValue.init(document.body);
  RainAmount.init(document.body);
  Nearby.init(document.body, { positionDetermined: (pos) => {
    updatePosCookie(pos);
  }});
  Map.init(document.body, { mapConfigList: window.FCGON.maps });
  refreshPosCookie()
});
