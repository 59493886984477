import { timestampsDiff } from 'components/utils/time';
import initLiveConditions from 'components/live-conditions';

class CurrentTideMarker {
  constructor({ css, el, units }) {
    this.css = css;
    this.el = el;
    this.units = units;
    this.heightEl = el.querySelector(`.${css.heightValue}`);
    this.arrowEl = el.querySelector(`.${css.arrow}`);
    this.textEl = el.querySelector(`.${css.text}`);
    this.nextEl = el.querySelector(`.${css.nextTide}`);
    this.infoEl = el.querySelector(`.${css.info}`);

    initLiveConditions(el, el.querySelector(`.${css.buoy}`), { units });
    this.units.onChange(() => this.renderHeight());
  }

  update({ prev, next, current }) {
    this.prev = prev;
    this.next = next;
    this.current = current;
    this.render();
    this.position();
  }

  updateTime(timestamp) {
    if (!this.next) return;

    const deltaStr = timestampsDiff(timestamp, this.next.timestamp);
    this.nextEl.innerText = deltaStr.length ? deltaStr : '<1min';
  }

  position() {
    const lowHighDiff = Math.abs(this.prev.height - this.next.height);
    const diff =
      this.current.height - Math.min(this.prev.height, this.next.height);
    const percentage = (diff / lowHighDiff) * 100;
    this.el.style.top = `${100 - percentage}%`;
    this.positionInfo();
  }

  renderHeight() {
    const height = this.units.formatLength(this.current.height);
    this.heightEl.innerText = `${height}${this.units.formatLengthUnits()}`;
  }

  render() {
    this.renderHeight();

    if (this.next.type === 'high') {
      this.arrowEl.classList.remove(this.css.arrowFalling);
      this.textEl.innerText = 'High tide in:';
    } else {
      this.arrowEl.classList.add(this.css.arrowFalling);
      this.textEl.innerText = 'Low tide in:';
    }
  }

  positionInfo() {
    if (parseFloat(this.el.style.top) > 78) {
      this.infoEl.classList.add(this.css.infoAbove);
    } else {
      this.infoEl.classList.remove(this.css.infoAbove);
    }
  }

  isCloseToTopBorder() {
    return parseFloat(this.el.style.top) < 10;
  }

  isCloseToBottomBorder() {
    return parseFloat(this.el.style.top) > 90;
  }
}

export default CurrentTideMarker;
